import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "./cssClasses/custom.css"
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import MobileMenu from './layout/MobileMenu';
import SideMenu from './layout/SideMenu';
import TopBar from './layout/TopBar';
import GeneralReport from './layout/GeneralReport';
import { getAPi, loginApi, postBearerToken, putApi } from '../utils/Apis';
import { useNavigate } from 'react-router-dom';
const Settings = () => {
    const navigate = useNavigate()

    React.useEffect(() => {


        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();


        return () => {

            // document.body.removeChild(externalScript);
        };
    }, []);
    const getRowId = (row) => row._id; // Replace 'customId' with your own unique identifier field

    const [rows, setrows] = React.useState([])

    React.useEffect(() => {
        fetch_data()
    }, [])

    const [keys, setkeys] = useState("")
    const [values, setvalues] = useState("")


    const fetch_data = async () => {

        getAPi("settings?all=1").then((result) => {
            if (result.status == "OK") {
                console.log(result.data)
                setrows(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })

    }
    const save_date = async () => {

        let json = {}



        loginApi("settings", {
            keys: keys,
            values: values,
        }).then((result) => {
            if (result.error_code == 0) {
                console.log(result.data)
                // setrows(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })

    }
    const updated_data = async () => {
        putApi("settings", {
            _id: selectedData?._id,
            keys: keys,
            values: values,
        }).then((result) => {
            if (result.status == "OK") {
                console.log(result.data)
                // setrows(result.data)
                fetch_data()
                setselectedData(null)
                setkeys("")
                setvalues("")
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })

    }


    const [selectedData, setselectedData] = useState(null)

    const columns = [

        {
            field: 'keys',
            headerName: 'Keys',
            headerClassName: "grid-header",
            flex: 1,
            // editable: true,
        },
        {
            field: 'values',
            headerName: 'Values',
            headerClassName: "grid-header",
            flex: 1,
        },


        {
            field: "action",
            headerName: "Details",
            flex: 1,
            headerClassName: "grid-header",
            renderCell: (params) => {
                return <button className="btn btn-primary btn-sm"
                    onClick={() => {
                        setselectedData(params.row)
                        setkeys(params.row.keys)
                        setvalues(params.row.values)
                    }}
                >Select</button>
            }
        }

    ];


    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
    const handleOpen = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const handleClose = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };
    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div
                className={`mobile-menu md:hidden ${isSideMenuOpen ? "mobile-menu--active" : ""
                    }`}
            >
                <MobileMenu handleOpen={handleOpen} handleClose={handleClose} />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar />
            {/* <!-- END: Top Bar --> */}
            <div className={`overflow-hidden ${isSideMenuOpen ? "" : "flex"}`}>
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu className={`side-nav ${isSideMenuOpen ? "open" : ""}`} />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                {/* <!-- BEGIN: General Report --> */}
                                <div className="col-span-12 mt-6">
                                    <div class="grid grid-cols-12 gap-6 mt-5">

                                        <div class="intro-y col-span-12 lg:col-span-4 ">
                                            <div class="intro-y box p-5">
                                                <div>
                                                    <input
                                                        id="crud-form-1"
                                                        value={keys}
                                                        onChange={(e) => setkeys(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Key"
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        id="crud-form-1"
                                                        value={values}
                                                        onChange={(e) => setvalues(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Value"
                                                    />
                                                </div>
                                                <div style={{ marginTop: 10 }}>

                                                    <button onClick={() => save_date()} className='btn btn-primary'>Add New</button>
                                                </div>


                                            </div>

                                        </div>
                                        {selectedData?
                                        <div class="intro-y col-span-12 lg:col-span-4 ">
                                            <div class="intro-y box p-5">
                                                <div>
                                                    <input
                                                        id="crud-form-1"
                                                        value={keys}
                                                        onChange={(e) => setkeys(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Key"
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        id="crud-form-1"
                                                        value={values}
                                                        onChange={(e) => setvalues(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Value"
                                                    />
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <button onClick={() => updated_data()} className='btn btn-primary'>Update</button>
                                                </div>
                                            </div>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                                <div className="col-span-12 mt-6">
                                    <div className="intro-y block sm:flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Settings
                                        </h2>
                                        <div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
                                            <button className="btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to Excel </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to PDF </button>
                                        </div>
                                    </div>
                                    <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                                        <DataGrid
                                            sx={{ mt: 1 }}
                                            rows={rows ? rows : []}
                                            columns={columns}
                                            showCellVerticalBorder
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10, 20, 40, 100]}
                                            getRowId={getRowId}

                                        />
                                    </div>

                                </div>
                                {/* <!-- END: Weekly Top Products --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default Settings