import React from 'react'
import ReactToPrint from "react-to-print";
import "./Modal.css"; // Import the CSS for styling
import { getAPi, getAPiUrltoken, loginApi, putApi, tripjackDirectApi } from '../../utils/Apis';

const DetailFligthTripJack = (props) => {
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        backgroundColor: "#f3f4f6",
    };

    const messagesStyle = {
        flexGrow: 1,
        overflowY: "auto",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
    };

    const messageStyle = {
        padding: "0.5rem 1rem",
        borderRadius: "0.5rem",
        marginBottom: "0.5rem",
        maxWidth: "70%",
        display: "inline-block",
    };

    const selfMessageStyle = {
        ...messageStyle,
        backgroundColor: "#3b82f6",
        color: "#fff",
        alignSelf: "flex-end",
        textAlign: "right",
    };

    const otherMessageStyle = {
        ...messageStyle,
        backgroundColor: "#e5e7eb",
        color: "#1f2937",
        alignSelf: "flex-start",
        textAlign: "left",
    };

    const inputContainerStyle = {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        padding: "1rem",
        backgroundColor: "#fff",
        boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
    };

    const selectStyle = {
        border: "1px solid #d1d5db",
        borderRadius: "0.375rem",
        padding: "0.5rem",
        width: "25%",
    };

    const inputStyle = {
        flexGrow: 1,
        border: "1px solid #d1d5db",
        borderRadius: "0.375rem",
        padding: "0.5rem",
    };

    const buttonStyle = {
        backgroundColor: "#3b82f6",
        color: "#fff",
        padding: "0.5rem 1rem",
        borderRadius: "0.375rem",
        cursor: "pointer",
        border: "none",
    };

    // const messages = [
    //     { id: 1, text: "Hello, how can I help you?", type: "other" },
    //     { id: 2, text: "I need assistance with my account.", type: "self" },
    //     { id: 3, text: "Sure, let me check that for you.", type: "other" },
    //     { id: 1, text: "Hello, how can I help you?", type: "other" },
    //     { id: 2, text: "I need assistance with my account.", type: "self" },
    //     { id: 3, text: "Sure, let me check that for you.", type: "other" },
    //     { id: 1, text: "Hello, how can I help you?", type: "other" },
    //     { id: 2, text: "I need assistance with my account.", type: "self" },
    //     { id: 3, text: "Sure, let me check that for you.", type: "other" },
    //     { id: 1, text: "Hello, how can I help you?", type: "other" },
    //     { id: 2, text: "I need assistance with my account.", type: "self" },
    //     { id: 3, text: "Sure, let me check that for you.", type: "other" },
    //     { id: 1, text: "Hello, how can I help you?", type: "other" },
    //     { id: 2, text: "I need assistance with my account.", type: "self" },
    //     { id: 3, text: "Sure, let me check that for you.", type: "other" },
    //     { id: 1, text: "Hello, how can I help you?", type: "other" },
    //     { id: 2, text: "I need assistance with my account.", type: "self" },
    //     { id: 3, text: "Sure, let me check that for you.", type: "other" },
    // ];
    const [isOpen, setIsOpen] = React.useState(false);
    const [cancellationType, setcancellationType] = React.useState("auto");
    const [formData, setFormData] = React.useState({
        textArea: "",
        input1: "",
        input2: "",
        select: "",
    });

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
        toggleModal();
    };

    const [transformedData, settransformedData] = React.useState([])
    const [originaltransformedData, setoriginaltransformedData] = React.useState([])
    const [wholeTrip, setwholeTrip] = React.useState(false)
    const transformData = () => {

        let a = props?.systemDetails?.search_request_data[0]?.search?.segments.map((segment) => ({
            src: segment.from_code,
            dest: segment.to_code,
            departureDate: segment.travel_date,
            travellers: props?.systemDetails?.request_data?.travellerInfo.map((traveller) => ({
                fn: traveller.fN,
                ln: traveller.lN
            }))
        }));
        settransformedData(a)
        return a

    };

    const [charges, setcharges] = React.useState(0)
    const viewCancellationCharges = async () => {
        const cancellationData = await transformData()
        console.log(JSON.stringify(cancellationData))


        // if no trips - whole cancellation

        let json = {
            "bookingId": props?.systemDetails?.request_data?.bookingId,
            "type": "CANCELLATION",
            "remarks": props?.systemDetails?.request_data?.bookingId+ " cancelling request",
        }
        if(wholeTrip==false){
            json["trips"] = originaltransformedData
        }
        console.log(json)

        tripjackDirectApi("view_change_charges", json).then((result) => {
            console.log(result)
            if (result.error_code == 0) {
                if (!result.data) {
                    setcancellationType("manual")
                } else {
                    
                    let z = result.data?.trips 
                    const totalFare = z.data.trips.reduce((acc, item) => {
                        const fares = Object.values(item.amendmentInfo).map(info => info.refundAmount);
                        return acc + fares.reduce((sum, fare) => sum + fare, 0);
                    }, 0);

                    setcharges(totalFare)
                }
            } else {
            }
        })
        

    }
    const submitCancellation = async () => {
        const cancellationData = await transformData()
        console.log(JSON.stringify(cancellationData))


        let json = {
            "bookingId": props?.systemDetails?.request_data?.bookingId,
            "type": "CANCELLATION",
            "remarks": "test CANCELLATION",
        }
        if(wholeTrip==false){
            json["trips"] = originaltransformedData
        }
        console.log(json)

        tripjackDirectApi("submit_change_charges", json).then((result) => {
            console.log(result)
            if (result.error_code == 0) {
                setcharges(0)
                setIsOpen(false)
            } else {

            }
        })
        

    }
    const submitDateChangeRequest = async () => {

        let json = {

            user: props?.systemDetails?.user,
            payment_id: props?.systemDetails?._id,
            remarks: formData?.textArea,
            request_type: formData.select == "CANCELLATION" && cancellationType == "manual" ? "CANCELLATION" : "DATECHANGE",
            request_status: "PROCESSING"

        }

        loginApi("store_new_request", json).then((result) => {
            console.log(result)
            if (result.error_code == 0) {
                setIsOpen(false)
            } else {
                alert(result.status)
            }
        })

    }

    const submitDateChangeRequestUpdate = async (status) => {

        let json = {
            _id: changeRequestData?._id,
            request_status: status,
        }

        putApi("update_request", json).then((result) => {
            console.log(result)
            if (result.error_code == 0) {
                setIsOpen(false)
            } else {
                alert(result.status)
            }
        })

    }


    const [changeRequestData, setchangeRequestData] = React.useState(null)
    const [changeRequestList, setchangeRequestList] = React.useState([])
    const fetchChangeRequest = async () => {
        getAPi("fetch_request?payment_id=" + props?.sys_id + "&type=" + props.type).then((result) => {
            console.log(result)
            if (result.status == "OK") {
                setchangeRequestData(result.data[0])
                setchangeRequestList(result.data)
                fetch_new_date_change_message(result?.data[0]?._id)
            } else {
            }
        })
    }
    const [messages, setmessages] = React.useState([])
    const fetch_new_date_change_message = async (_id) => {
        // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNoYXVuQG1haWwuaW4iLCJpYXQiOjE3MzYzMTMwMTMsImV4cCI6MTczODA0MTAxM30.l-RmatG_AwYv66FGyqLgBwNUma144f192Ox_R2ZoDgk"
        getAPi("fetch_new_date_change_message?_id=" + _id).then((result) => {
            console.log(result)
            if (result.status == "OK") {
                setmessages(result.data)
            } else {
            }
        })
    }
    const [enterMessage, setenterMessage] = React.useState("")
    const submitDateChangeMessage = async () => {

        let json = {

            user: props.user_id,
            message: enterMessage,
            date_change_request_id: changeRequestData?._id,

        }

        loginApi("store_new_date_change_message", json).then((result) => {
            console.log(result)
            if (result.status == "OK") {
                fetch_new_date_change_message(changeRequestData?._id)
            } else {
            }
        })

    }

    React.useEffect(() => {
        fetchChangeRequest()
        return () => {
        }
    }, [])


    // Handler for selecting/unselecting traveler checkboxes
    const handleTravelerChange = (dataIndex, travelerIndex, isChecked) => {
        setoriginaltransformedData((prevSelectedData) => {
            // Copy the selected data for modification
            const updatedData = [...prevSelectedData];
            const targetData = transformedData[dataIndex];
            const travelerToAdd = targetData.travellers[travelerIndex];

            // Find or create the object for the current data item
            let selectedObject = updatedData.find(
                (item) => item.src === targetData.src && item.dest === targetData.dest
            );

            if (isChecked) {
                if (!selectedObject) {
                    selectedObject = {
                        src: targetData.src,
                        dest: targetData.dest,
                        departureDate: targetData.departureDate,
                        travellers: [],
                    };
                    updatedData.push(selectedObject);
                }
                // Add traveler only if not already present
                if (
                    !selectedObject.travellers.some(
                        (traveler) =>
                            traveler.fn === travelerToAdd.fn && traveler.ln === travelerToAdd.ln
                    )
                ) {
                    selectedObject.travellers.push(travelerToAdd);
                }
            } else {
                if (selectedObject) {
                    // Remove traveler
                    selectedObject.travellers = selectedObject.travellers.filter(
                        (traveler) =>
                            traveler.fn !== travelerToAdd.fn || traveler.ln !== travelerToAdd.ln
                    );
                    // Remove entire object if no travelers left
                    if (selectedObject.travellers.length === 0) {
                        const indexToRemove = updatedData.indexOf(selectedObject);
                        updatedData.splice(indexToRemove, 1);
                    }
                }
            }
            return updatedData;
        });
    };

    // Handler for selecting/unselecting entire data item checkboxes
    const handleDataChange = (dataIndex, isChecked) => {
        setoriginaltransformedData((prevSelectedData) => {
            const updatedData = [...prevSelectedData];
            const targetData = transformedData[dataIndex];

            if (isChecked) {
                updatedData.push(targetData);
            } else {
                const indexToRemove = updatedData.findIndex(
                    (item) => item.src === targetData.src && item.dest === targetData.dest
                );
                if (indexToRemove !== -1) updatedData.splice(indexToRemove, 1);
            }

            return updatedData;
        });
    };

    return (
        <div class="content" style={{ width: "100vw" }}>

            <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                <h2 class="text-lg font-medium mr-auto">
                    Transaction Details
                </h2>
                <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

                    <button
                        onClick={() => props.handlePrint()}
                        class="btn btn-primary shadow-md mr-2">Print</button>
                    <div class="dropdown ml-auto sm:ml-0">
                        <button class="dropdown-toggle btn px-2 box" aria-expanded="false" data-tw-toggle="dropdown">
                            <span class="w-5 h-5 flex items-center justify-center"> <i class="w-4 h-4" data-lucide="plus"></i> </span>
                        </button>
                        <div class="dropdown-menu w-40">
                            <ul class="dropdown-content">
                                <li>
                                    <a href="" class="dropdown-item"> <i data-lucide="file" class="w-4 h-4 mr-2"></i> Export Word </a>
                                </li>
                                <li>
                                    <a href="" class="dropdown-item"> <i data-lucide="file" class="w-4 h-4 mr-2"></i> Export PDF </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intro-y grid grid-cols-11 gap-5 mt-5">
                <div class="col-span-12 lg:col-span-7 2xl:col-span-12">
                    <div class="box p-5 rounded-md">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">{"Passenger Info"}</div>
                            {/* <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="plus" class="w-4 h-4 mr-2"></i> Add Notes </a> */}
                        </div>
                        <div class="overflow-auto lg:overflow-visible -mt-3">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="whitespace-nowrap text-right">NAME</th>
                                        <th class="whitespace-nowrap text-right">TYPE</th>
                                        <th class="whitespace-nowrap text-right">DOB</th>
                                        <th class="whitespace-nowrap text-right">Baggage</th>
                                        <th class="whitespace-nowrap text-right">Meal</th>
                                        <th class="whitespace-nowrap text-right">Seats</th>
                                        <th class="whitespace-nowrap text-right">Pnr</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        props.details?.itemInfos?.AIR.travellerInfos &&
                                            props.details?.itemInfos?.AIR.travellerInfos.length > 0 ? (
                                            props.details?.itemInfos?.AIR.travellerInfos.map((item, index) => (
                                                <tr key={index}>

                                                    <td className="text-right">
                                                        {item.ti} {item.fN} {item.lN}
                                                    </td>
                                                    <td className="text-right">{item.pt}</td>
                                                    <td className="text-right">{item.dob || 'N/A'}</td>
                                                    <td className="text-right">
                                                        {Object.entries(item.ssrBaggageInfos || {}).map(
                                                            ([key, baggage]) => (
                                                                <div key={key}>
                                                                    <strong>{key}:</strong> {baggage.desc} ({baggage.amount || 'N/A'})
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="text-right">
                                                        {Object.entries(item.ssrMealInfos || {}).map(
                                                            ([key, meal]) => (
                                                                <div key={key}>
                                                                    <strong>{key}:</strong> {meal.desc} ({meal.amount || 'N/A'})
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="text-right">
                                                        {Object.entries(item.ssrSeatInfos || {}).map(
                                                            ([key, seat]) => (
                                                                <div key={key}>
                                                                    <strong>{key}:</strong> {seat.code} ({seat.amount || 'N/A'})
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="text-right">
                                                        {Object.entries(item.pnrDetails || {}).map(
                                                            ([key, pnr]) => (
                                                                <div key={key}>
                                                                    <strong>{key}:</strong> {pnr}
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : null
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intro-y grid grid-cols-11 gap-5 mt-5">
                <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
                    <div class="box p-5 rounded-md">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Transaction Details</div>
                            <a href="#" onClick={() => {
                                transformData()
                                setIsOpen(true)
                            }} class="flex items-center ml-auto text-primary"> <i data-lucide="edit" class="w-4 h-4 mr-2"></i> Change Status </a>
                        </div>
                        <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Booking ID : <a href="" class="underline decoration-dotted ml-1">{props.details?.order?.bookingId}</a> </div>
                        <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Booking Date: {props.details?.order?.createdOn.split("T")[0]} {props.details?.order?.createdOn.split("T")[1]} </div>
                        <div class="flex items-center mt-3"> <i data-lucide="clock" class="w-4 h-4 text-slate-500 mr-2"></i> Transaction Status: <span class="bg-success/20 text-success rounded px-2 ml-1">{props.details?.order?.status}</span> </div>
                    </div>
                    <div class="box p-5 rounded-md mt-5">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Buyer Details</div>
                            {/* <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="edit" class="w-4 h-4 mr-2"></i> View Details </a> */}
                        </div>
                        {/* <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Name: <a href="" class="underline decoration-dotted ml-1">{"Robert De Niro"}</a> </div> */}
                        <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Phone Number: {props.details?.order?.deliveryInfo.contacts[0]} </div>
                        <div class="flex items-center mt-3"> <i data-lucide="map-pin" class="w-4 h-4 text-slate-500 mr-2"></i> Email: {props.details?.order?.deliveryInfo.emails[0]} </div>
                    </div>
                    <div class="box p-5 rounded-md mt-5">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Payment Details</div>
                        </div>
                        <div class="flex items-center">
                            <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Payment Method:
                            <div class="ml-auto">Online</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <i data-lucide="credit-card" class="w-4 h-4 text-slate-500 mr-2"></i> Total Price :
                            <div class="ml-auto">Rs. {props.details?.order?.amount}</div>
                        </div>
                        {/* <div class="flex items-center mt-3"> */}
                        {/* <i data-lucide="credit-card" class="w-4 h-4 text-slate-500 mr-2"></i> Convinence Fee: */}
                        {/* <div class="ml-auto">Rs. {10}</div> */}
                        {/* </div> */}

                        <div class="flex items-center border-t border-slate-200/60 dark:border-darkmode-400 pt-5 mt-5 font-medium">
                            <i data-lucide="credit-card" class="w-4 h-4 text-slate-500 mr-2"></i> Grand Total:
                            {/* <div class="ml-auto">Rs. {props.details.order.amount + 10}</div> */}
                        </div>
                    </div>

                </div>
                <div className="col-span-12 lg:col-span-7 2xl:col-span-8">
                    <div className="box p-5 rounded-md">
                        <div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div className="font-medium text-base truncate">{"Segments"}</div>
                            {/* <a href="#" className="flex items-center ml-auto text-primary">
                                <i data-lucide="plus" className="w-4 h-4 mr-2"></i> Add Notes
                            </a> */}
                        </div>
                        <div className="overflow-auto lg:overflow-visible -mt-3">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap !py-5">Name</th>
                                        <th className="whitespace-nowrap text-right">Departure</th>
                                        <th className="whitespace-nowrap text-right">Departure Terminal</th>
                                        <th className="whitespace-nowrap text-right">Arrival</th>
                                        <th className="whitespace-nowrap text-right">Arrival Terminal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.details?.itemInfos?.AIR?.tripInfos && props.details?.itemInfos?.AIR?.tripInfos.length > 0 ? (
                                        props.details?.itemInfos?.AIR?.tripInfos.map((trip, index) => (
                                            <React.Fragment key={`trip-${index}`}>
                                                <tr>
                                                    <td colSpan="5">
                                                        <hr style={{ height: 1, backgroundColor: "#ccc" }} />
                                                    </td>
                                                </tr>
                                                {trip.sI && trip.sI.length > 0 ? (
                                                    trip.sI.map((segment, sIndex) => (
                                                        <tr key={`segment-${sIndex}`}>
                                                            <td className="!py-4">
                                                                <div className="flex items-center">
                                                                    <div className="w-10 h-10 image-fit zoom-in">
                                                                        <img
                                                                            alt="Airline Logo"
                                                                            className="rounded-lg border-2 border-white shadow-md tooltip"
                                                                            src="dist/images/preview-2.jpg"
                                                                            title={`Flight Number: ${segment.fD.fN}`}
                                                                        />
                                                                    </div>
                                                                    <a href="#" className="font-medium whitespace-nowrap ml-4">
                                                                        {segment.fD.aI.name}-{segment.fD.aI.code}
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className="text-right">
                                                                {segment.da.code}<br />
                                                                {segment.da.name}
                                                            </td>
                                                            <td className="text-right">
                                                                {segment.da.terminal || "N/A"}<br />
                                                                {new Date(segment.dt).toLocaleString()}
                                                            </td>
                                                            <td className="text-right">
                                                                {segment.aa.code}<br />
                                                                {segment.aa.name}
                                                            </td>
                                                            <td className="text-right">
                                                                {segment.aa.terminal || "N/A"}<br />
                                                                {new Date(segment.at).toLocaleString()}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">No segments available</td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No trip information available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* change  */}
            {
                changeRequestList && changeRequestList.length > 0
                    ?
                    <div class="intro-y grid grid-cols-11 gap-5 mt-5">
                        <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
                            <div class="box p-5 rounded-md">
                                <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                                    <div class="font-medium text-base truncate">Amendment Request</div>
                                </div>
                                {/* <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Booking ID : <a href="#" class="underline decoration-dotted ml-1">{props.details?.order?.bookingId}</a> </div>
                        <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Booking Date: {props.details?.order?.createdOn.split("T")[0]} {props.details?.order?.createdOn.split("T")[1]} </div> */}
                            </div>
                            {
                                changeRequestList && changeRequestList.length > 0
                                    ?
                                    changeRequestList.map((item, index) => (
                                        <div onClick={() => {
                                            setchangeRequestData(item)
                                            fetch_new_date_change_message(item._id)
                                        }} class="box p-5 rounded-md mt-5">
                                            <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                                                <div class="font-medium text-base truncate">{item?.request_type}</div>
                                                <a href="#" class="flex items-center ml-auto text-primary"> <i data-lucide="edit" class="w-4 h-4 mr-2"></i> Status : {item?.request_status} </a>
                                            </div>
                                            {/* <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Name: <a href="" class="underline decoration-dotted ml-1">{"Robert De Niro"}</a> </div> */}
                                            <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Remarks: {item?.remarks} </div>
                                            <div class="flex items-center mt-3"> <i data-lucide="map-pin" class="w-4 h-4 text-slate-500 mr-2"></i> Created: {item?.created_at.split("T")[0]} {item?.created_at.split("T")[1].split(".")[0]}  </div>
                                        </div>
                                    ))
                                    :
                                    null
                            }

                        </div>
                        <div className="col-span-12 lg:col-span-7 2xl:col-span-8">
                            <div className="box p-5 rounded-md">
                                <div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                                    <div className="font-medium text-base truncate">{"Mesages"}</div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: messages && messages.length > 0 ? "100vh" : "1vh",
                                    width: "100%",
                                    backgroundColor: "#f3f4f6",
                                }}>
                                    {/* Chat Messages */}
                                    <div style={messagesStyle}>
                                        {messages.map((message) => (
                                            <div
                                                key={message._id}
                                                style={message.user === props.user_id ? selfMessageStyle : otherMessageStyle}
                                            >
                                                {message.message}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Input and Dropdown */}

                                    {
                                        props.type.toLowerCase() != 'admin' && changeRequestData?.request_status?.toLowerCase() != "processing"
                                            ? null
                                            :
                                            <div style={inputContainerStyle}>

                                                {
                                                    props.type == "admin"
                                                        ?
                                                        <select onChange={(e) => submitDateChangeRequestUpdate(e.target.value)} style={selectStyle} aria-label="Select option">
                                                            <option value="PROCESSING">PROCESSING</option>
                                                            <option value="REJECTED">REJECTED</option>
                                                            <option value="COMPLETED">COMPLETED</option>
                                                        </select>
                                                        : null

                                                }
                                                <input
                                                    type="text"
                                                    placeholder="Type your message..."
                                                    style={inputStyle}
                                                    value={enterMessage}
                                                    onChange={(e) => setenterMessage(e.target.value)}
                                                />

                                                <button onClick={() => {
                                                    submitDateChangeMessage()
                                                    setenterMessage("")
                                                }} style={buttonStyle}>Send</button>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>


                    </div>
                    :
                    null
            }




            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Ticket Modifictaion</h2>
                        <form onSubmit={handleSubmit}>
                            <select
                                name="select"
                                value={formData.select}
                                onChange={handleChange}
                            >
                                <option value="">Select an option</option>
                                <option value="CANCELLATION">Cancellation</option>
                                <option value="DATECHANGE">Date Change</option>
                            </select>
                            {
                                formData.select == "CANCELLATION" && cancellationType == "auto"
                                    ? null
                                    :
                                    <>
                                        <textarea
                                            name="textArea"
                                            placeholder="Enter reason here"
                                            value={formData.textArea}
                                            onChange={handleChange}
                                            rows="4"
                                        />
                                        {/* <input
                                            type="text"
                                            name="input1"
                                            placeholder="Input Box 1"
                                            value={formData.input1}
                                            onChange={handleChange}
                                        />
                                        <input
                                            type="text"
                                            name="input2"
                                            placeholder="Input Box 2"
                                            value={formData.input2}
                                            onChange={handleChange}
                                        /> */}
                                    </>
                            }


                            {/*  */}

                            <div style={{ display: "flex", flexDirection: "row", fontFamily: 'Arial, sans-serif', padding: '20px' }}>
                                <label
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        marginRight: '20px', // Add margin for spacing between checkboxes
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        value="Whole Trip"
                                        //   checked={selectedOption === 'Whole Trip'}
                                        onChange={(e) => setwholeTrip(e.target.checked)}
                                        name="tripOption" // Same name groups them together like radio buttons
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                    Whole Trip
                                </label>


                            </div>

                            {
                                wholeTrip ? null :
                                    <label>Select To Cancel</label>
                            }
                            {/* ppl */}
                            {
                                wholeTrip ? null :

                                    <div style={{ display: "flex", flexDirection: "column", fontFamily: 'Arial, sans-serif', padding: '20px' }}>

                                        {
                                            transformedData && transformedData.length > 0
                                                ?
                                                transformedData.map((item, index) => (
                                                    <>
                                                        <label
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '10px',
                                                            }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value="One Way"
                                                                //   checked={selectedOption === 'One Way'}
                                                                onChange={(e) =>
                                                                    handleDataChange(
                                                                        index,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                name="tripOption" // Same name groups them together like radio buttons
                                                                style={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                            {item.src} - {item.dest} {item.departureDate}
                                                        </label>
                                                        <div style={{ display: "flex", flexDirection: "row", fontFamily: 'Arial, sans-serif', paddingTop: '20px', paddingBottom: '20px' }}>

                                                            {
                                                                item.travellers && item.travellers.length > 0
                                                                    ?
                                                                    item.travellers.map((travller, travellerindex) => (
                                                                        <label
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                gap: '10px',
                                                                                marginRight: "10px"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value="One Way"
                                                                                onChange={(e) =>
                                                                                    handleTravelerChange(
                                                                                        index,
                                                                                        travellerindex,
                                                                                        e.target.checked
                                                                                    )
                                                                                }
                                                                                //   checked={selectedOption === 'One Way'}
                                                                                //   onChange={handleCheckboxChange}
                                                                                name="tripOption" // Same name groups them together like radio buttons
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            />
                                                                            {travller.fn} {travller.ln}
                                                                        </label>
                                                                    ))
                                                                    :
                                                                    null
                                                            }



                                                        </div>
                                                    </>
                                                ))
                                                :
                                                null
                                        }



                                    </div>
                            }
                            {/* ppl */}



                            {
                                formData.select == "CANCELLATION"
                                    ?
                                    cancellationType == "auto" ?
                                        <>
                                        <button onClick={() => viewCancellationCharges()} type="button">View Charges</button>
                                        {
                                            charges > 0 
                                            ?
                                            <>
                                            <lable>Charges = Rs. {charges}</lable>
                                            <button onClick={() => submitCancellation()} type="button">Submit Cancellation</button>
                                            </>
                                            :
                                            null
                                        }
                                        </>
                                        :
                                        <button onClick={() => submitDateChangeRequest()} type="button">Submit Manual Cancellation</button>
                                    :
                                    <button onClick={() => submitDateChangeRequest()} type="button">Submit</button>

                            }

                        </form>
                        <button className="close-button" onClick={toggleModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}

        </div>
    )
}

export default DetailFligthTripJack