import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalContext } from "./ContextProvider";
import Login from "../pages/Login";
import User from "../pages/User";
import Reports from "../pages/Reports";
import HotelList from "../pages/HotelList";
import FlightList from "../pages/FlightList";
import Queries from "../pages/Queries";
import DetailsHotels from "../pages/DetailsHotels";
import DetailsFlights from "../pages/DetailFlights";
import Settings from "../pages/Settings";
import DetailsFlightsAdmin from "../pages/DetailFlightsAdmin";

const MainNavigation = () => {

    const { isGlobalAccess, setGlobalAccess, isGlobalAccessType } = React.useContext(GlobalContext);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="user" element={<User />} />
                <Route path="reports" element={<Reports />} />
                <Route path="hotel-booking-list" element={<HotelList />} />
                <Route path="hotel-booking-details" element={<DetailsHotels />} />
                <Route path="fligth-booking-details" element={<DetailsFlights />} />
                <Route path="admin-fligth-booking-details" element={<DetailsFlights />} />
                <Route path="flight-booking-list" element={<FlightList />} />
                <Route path="/" element={<FlightList />} />
                <Route path="queries" element={<Queries />} />
                <Route path="settings" element={<Settings />} />
                {/* <Route
                    path="/"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "ADMIN"
                                    ?
                                    <Home />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                /> */}
                {/* <Route path="*" element={<PageNotFound />} /> */}
                {/* <Route
                    path="/"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Home />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />

                <Route
                    path="/sa-home"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Home />
                                    :
                                    <Login />
                            }

                        </Protected>
                    }
                />
                <Route
                    path="/sa-add-expense-type"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <ExpenseType />
                                    :
                                    <Login />
                            }

                        </Protected>
                    }
                />
                <Route
                    path="/sa-add-admin"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Owner />
                                    :
                                    <Login />
                            }

                        </Protected>
                    }
                /> */}
                {/*<Route
                    path="/a-home"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "A"
                                    ?
                                    <A_Home />
                                    :
                                    <Forbidden />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/a-entry"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "A"
                                    ?
                                    <A_add_entry />
                                    :
                                    <Forbidden />
                            }
                        </Protected>
                    }
                /> */}



            </Routes>
        </BrowserRouter>
    );
};

export default MainNavigation;
