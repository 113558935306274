import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../navigation/ContextProvider";

const SideMenu = (props) => {
  const search = useLocation().pathname;
  const navigate = useNavigate();
  const {
    isGlobalAccess,
    setGlobalAccess,
    isGlobalAccessType,
    setGlobalAccessType,
  } = React.useContext(GlobalContext);
  // console.log(isGlobalAccessType);

  return (
    <nav className={props.className}>
      <ul>
       
      <li>
          <div
            className={"side-menu"}
            onClick={() => {
              localStorage.clear();
              navigate("/flight-booking-list");
            }}
          >
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="users"></i>{" "}
            </div>
            <div className="side-menu__title">Flight Booking</div>
          </div>
        </li>
        <li>
          <div
            className={"side-menu"}
            onClick={() => {
              localStorage.clear();
              navigate("/hotel-booking-list");
            }}
          >
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="users"></i>{" "}
            </div>
            <div className="side-menu__title">Hotel Booking</div>
          </div>
        </li>
        <li>
          <div
            className={"side-menu"}
            onClick={() => {
              localStorage.clear();
              navigate("/queries");
            }}
          >
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="users"></i>{" "}
            </div>
            <div className="side-menu__title">Queries</div>
          </div>
        </li>
        <li>
          <div
            className={"side-menu"}
            onClick={() => {
              localStorage.clear();
              navigate("/reports");
            }}
          >
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="users"></i>{" "}
            </div>
            <div className="side-menu__title">Reports</div>
          </div>
        </li>
        <li>
          <div
            className={"side-menu"}
            onClick={() => {
              localStorage.clear();
              navigate("/settings");
            }}
          >
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="users"></i>{" "}
            </div>
            <div className="side-menu__title">Settings</div>
          </div>
        </li>
        <li>
          <div
            className={"side-menu"}
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
          >
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="users"></i>{" "}
            </div>
            <div className="side-menu__title">Logout</div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default SideMenu;
