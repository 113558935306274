import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "./cssClasses/custom.css"
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import MobileMenu from './layout/MobileMenu';
import SideMenu from './layout/SideMenu';
import TopBar from './layout/TopBar';
import GeneralReport from './layout/GeneralReport';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getAPi, loginApi, tripjackDirectApi } from '../utils/Apis';
import './cssClasses/hotelDetail.css'; // Import your CSS file
import DetailHotelTripJack from './layout/DetailHotelTripJack';
import DetailFligthTripJack from './layout/DetiailFlightTripJack';
import DetailFlightTbo from './layout/DetailFlightTbo';
import { useReactToPrint } from "react-to-print";

const DetailsFlights = (props) => {

    const printRef = React.useRef();

    // const contentRef = useRef<HTMLDivElement>(null);
    // const handlePrint = useReactToPrint({ contentRef : printRef.current.innerHTML });

    const handlePrint = () => {
        window.print()
    }

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search)
    // alert(JSON.stringify(location))
    const _id = queryParameters.get("_id")
    const sys_id = queryParameters.get("sys_id")
    const user_id = queryParameters.get("user_id")

    // const dataReceived = location.state;
    const [details, setdetails] = React.useState(null)
    const [systemDetails, setsystemDetails] = React.useState(null)
    const [api, setapi] = React.useState("")
    // console.log(dataReceived ,_id )

    React.useEffect(() => {


        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();


        return () => {

            // document.body.removeChild(externalScript);
        };
    }, []);

    const [rows, setrows] = React.useState([])

    React.useEffect(() => {
        fetch_details()
        fetch_details_flight()
    }, [])


    const fetch_details_flight = async () => {


        tripjackDirectApi("booking-details", {
            "bookingId": _id
        }).then((result) => {
            if (result.error_code == 0) {
                // console.log(result.data)
                setdetails(result.data)
                // setapi(result.api)
            } else {
            }
        })

    }
    const fetch_details = async () => {


        loginApi("fetch_payment_data?_id="+sys_id, {
            "_id": ""
        }).then((result) => {
            if (result.error_code == 0) {
                setsystemDetails(result.data)
            } else {
            }
        })

    }





    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
    const handleOpen = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const handleClose = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };
    return (
        <div className="py-5 md:py-0" ref={printRef} id="print-section">
            <div className={`overflow-hidden ${isSideMenuOpen ? "" : "flex"}`}>
                
                <div className='row'>
                    <DetailFligthTripJack 
                    type= {location.pathname.includes("admin") ? "admin" : "user"} 
                    user_id={user_id} handlePrint={handlePrint} details={details} 
                    systemDetails={systemDetails} 
                    sys_id = {sys_id}/>
                </div>
            </div>
        </div>
    )
}

export default DetailsFlights