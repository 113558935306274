import axios from 'axios'
import { Constants } from './Constants';

export const BASE_URL = "https://travvoo.com:6111/";
export const BASE_URL_TRIPJACK = "https://travvoo.com:6110/";
// export const BASE_URL_TRIPJACK = "http://localhost:6110/";
// export const BASE_URL = "http://localhost:6111/";
export const projectname = "Travoo";

export const loginApi = async (endpoint, data) => {
    return await axios.post(BASE_URL + endpoint, data).then((result) => {
        return result.data
    }).catch(e => {
        return e.response
    });
}

export const tripjackDirectApi = async (endpoint, data) => {
    return await axios.post(BASE_URL_TRIPJACK + endpoint, data).then((result) => {
        return result.data
    }).catch(e => {
        return e.response
    });
}

export const otpApi = async (endpoint, data) => {
    return await axios.get(BASE_URL + endpoint).then((result) => {
        if (result.data.error_code == 0 ) {
            console.log("Success", result)
            return result.data
        } else {
            console.log("Error", result.data)
            return 500
        }
    }).catch(e => {
        console.log("Error", e)
        return 500
    });
}

export const postBearerToken = async (endpoint, data) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.post( BASE_URL+endpoint , data , config ).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const putBearerToken = async (endpoint, data) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.put( BASE_URL+endpoint , data , config ).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const getBearerToken = async (endpoint) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.get( BASE_URL+endpoint , config ).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const getAPi = async (endpoint) => {
    
    return await axios.get( BASE_URL+endpoint  ).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const getAPiUrltoken = async (endpoint , token) => {
    
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.get( BASE_URL+endpoint , config ).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const putApi = async (endpoint, data) => {
    return await axios.put(BASE_URL + endpoint, data).then((result) => {
        return result.data
    }).catch(e => {
        return e.response
    });
}